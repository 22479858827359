import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Load } from '../load/lib/load';
import { LoadEventObject } from '../load/lib/load-event-object';
import { LoadService } from '../load/load.service';
import { Project } from '../projects/lib/project';
import { ProjectsService } from '../projects/projects.service';
import { ProfileService } from '../services/profile.service';
import { ImportFileService } from './import-file.service';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.less']
})
export class ImportFileComponent implements OnInit, OnDestroy {
  @Input()
  requiredFileType: string;
  private placeholder = $localize`Załącz pliki tutaj`;
  fileName = this.placeholder;
  uploadProgress: number;
  uploadSub?: Subscription;
  public invalidFormat = false;
  public summary: string = null;
  public actionType = 'append';
  public advanced = false;
  protected isLoading = false;

  private unsubscribe$ = new Subject<void>();
  private project: Project;
   private file: File;
  private oldLoads: Load[];

  constructor(
    private service: ImportFileService,
    private profileService: ProfileService,
    private projectService: ProjectsService,
    private loadService: LoadService
  ) {}

  ngOnInit(): void {
    this.projectService.currentProject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((project) => (this.project = project));
    this.service
      .getCurrentCustomLoads()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loads) => {
        this.oldLoads = loads;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    if (this.summary !== null) {
      this.service.openMyCuboids();
    } else {
      this.service.close();
    }
  }

  downloadTemplate() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '/assets/wzor_importu_ladunkow.csv');
    link.setAttribute('download', 'loadings.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.invalidFormat = false;
    }
  }


  async upload() {
    if (this.file) {
      this.fileName = this.file.name;
      this.invalidFormat = false;
      this.isLoading = true;
      this.uploadSub = this.service
        .uploadFile(this.file, this.project.uuid, this.actionType)
        .pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
          if (response.message === 'INVALID_FORMAT') {
            this.invalidFormat = true;
            this.reset();
          } else if (response.message === 'OK') {
            this.invalidFormat = false;
            this.service.updateCustomLoadList();
            this.summary = $localize`Zaimportowano`;
            if (this.profileService.currentSettings.autoAddCustomLoads) {
              const loads = response.addedLoads;
              this.loadService.addLoadAmount(loads).subscribe();
            }
          }
        });
    }
  }

  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = 0;
    this.uploadSub = undefined;
    this.fileName = this.placeholder;
    this.isLoading = true;
  }
}
